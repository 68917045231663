
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import sberbank from './img/sber-logo.png';
import tinkoff from './img/tinkoff-logo.png';
import vkusvill from './img/vkusvill-logo.png';
import ingosstrah from './img/ingosstrah-logo.png';
import fresh from './img/fresh-logo.png';
import samokat from './img/samokat-logo.png';
import ozon from './img/ozon-logo.png';
import alfa from './img/alfa-logo.png';

const logos = [sberbank, alfa, samokat, tinkoff, ozon, vkusvill, ingosstrah, fresh];

const Carousel = () => {
    const settings = {
        autoplay: true,
        autoplaySpeed: 0,
        speed: 4000,
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: true,
    };

    return (
        <div className="carousel-container">
            <Slider {...settings}>
                {logos.map((logo, index) => (
                    <div key={index} className="carousel-slide">
                        <img src={logo} alt="Логотип" />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Carousel;